<template>
  <div class="w-p-100 h-p-100 bg-f3">
    <div class="h-calc-45 scroll-y">
      <div
        class="lP-10 rP-10 flex alignCenter justifyBetween"
        v-if="route.params.is_qualified == 0"
      >
        <div class="h-35 w-p-100 flex alignCenter">第{{ answercount }}次</div>
        <div class="h-35 w-p-100 flex alignCenter justifyCenter">
          {{ times }}
        </div>
        <div class="h-35 w-p-100 flex alignCenter justifyEnd">
          <div
            class="bg-0F60A7 flex alignCenter justifyCenter color-fff padding-5 lP-10 rP-10"
            @click="next(1)"
          >
            提交试卷
          </div>
        </div>
      </div>
      <div class="lP-10 rP-10 tP-10 bP-10 Fbg bM-10">
        <div
          class="flex alignCenter"
          v-for="(item, index) in answerForm"
          :key="index"
        >
          <div class="size-16 w-60">
            {{
              index == 1
                ? "判断题"
                : index == 2
                ? "多选题"
                : index == 3
                ? "单选题"
                : index == 4
                ? "简答题"
                : ""
            }}
          </div>
          <ul class="flex alignCenter flex-warp w-calc-60">
            <li
              class="h-24 w-24 radius-24 flex size-14 alignCenter justifyCenter lM-3 rM-3 tM-3 bM-3 color-fff"
              :class="{
                'bg-0F60A7':
                  (answerDetail.id == items.id ||
                    (items.ct_question_test_dtl_item_copy_id != null &&
                      items.ct_question_test_dtl_item_copy_id.length > 0)) &&
                  items.is_correct == -1,
                'bg-67c23a': items.is_correct == 1,
                'bg-ff0000': items.is_correct == 0,
                'bg-D5D5D5': items.is_correct == -1
              }"
              v-for="(items, index) in item"
              :key="index"
              @click="checkAnswerKey(items.id)"
            >
              {{ index + 1 }}
            </li>
          </ul>
        </div>
      </div>
      <div class="Fbg scroll-y w-p-100 scroll-x">
        <div class="lP-10 rP-10 h-35 flex alignCenter">题目:</div>
        <div
          class="lP-10 rP-10 tP-20 w-calc-20"
          v-html="answerDetail.name"
        ></div>
        <div class="lP-10 rP-10 tP-20 bP-20 w-calc-20">
          <el-radio-group
            v-if="answerDetail.types == 1 || answerDetail.types == 3"
            v-model="answerDetail.ct_question_test_dtl_item_copy_id"
            class="flex flex-warp"
          >
            <el-radio
              class="h-35 w-p-100 flex alignCenter "
              :label="items.id.toString()"
              v-for="(items,
              index) in answerDetail.ct_question_test_dtl_item_copy"
              :key="index"
              >{{ items.content }}</el-radio
            ><br />
          </el-radio-group>
          <el-checkbox-group
            v-model="ct_question_test_dtl_item_copy_ids"
            v-if="answerDetail.types == 2"
          >
            <el-checkbox
              class="h-35 w-p-100 flex alignCenter "
              :label="items.id.toString()"
              v-for="(items,
              index) in answerDetail.ct_question_test_dtl_item_copy"
              :key="index"
              >{{ items.content }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div
      class="size-18 w-p-100 flex alignCenter justifyCenter"
      v-if="route.params.is_qualified == 0"
    >
      <div
        class="h-35 flex alignCenter justifyCenter w-p-100 bg-808080 color-fff padding-5"
        v-if="checkNum != 0"
        @click="prev()"
      >
        上一题
      </div>
      <van-button
        type="success"
        :disabled="isloading"
        :loading="isloading"
        loading-text="提交中"
        @click="next(checkNum != answerList.length - 1 ? 0 : 1)"
        class="h-45 bg-0F60A7 flex alignCenter justifyCenter  w-p-100 color-fff padding-5"
        >{{
          checkNum != answerList.length - 1 ? "提交下一题" : "提交试卷"
        }}</van-button
      >
      <!-- <div class="h-35 bg-0F60A7 flex alignCenter justifyCenter  w-p-100 color-fff padding-5" @click="next(checkNum != answerList.length - 1?0:1)">{{checkNum != answerList.length - 1?'提交下一题':'提交试卷'}}</div> -->
      <!-- <div class="h-35 bg-0F60A7 flex alignCenter justifyCenter  w-p-100 color-fff padding-5" v-if="checkNum == answerList.length - 1" @click="submit()">提交试卷</div> -->
    </div>
    <div
      class="size-18 w-p-100 flex alignCenter justifyCenter"
      v-if="route.params.is_qualified == 1"
    >
      <div
        class="h-35 flex alignCenter justifyCenter w-p-100 bg-808080 color-fff padding-5"
        v-if="checkNum != 0"
        @click="prev()"
      >
        上一题
      </div>
      <div
        class="h-35 bg-0F60A7 flex alignCenter justifyCenter  w-p-100 color-fff padding-5"
        v-if="checkNum != answerList.length - 1"
        @click="next(2)"
      >
        下一题
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { reactive, ref } from '@vue/reactivity'
import http from '../../../api/http'
import { useRoute, useRouter } from 'vue-router'
import { onMounted } from '@vue/runtime-core'
import { Dialog,Notify } from 'vant'
export default {
    setup() {
        const route = useRoute();
        const router = useRouter();
        const answerList = ref([]);
        const answerForm = reactive({});
        const isCheck = ref('');
        const checkNum = ref(0)
        const answercount = ref('');
        const answerDetail = reactive({
            method:'query',
            queryId:1066,
            t:'CT_QUESTION_TEST_DTL_FORM',
            name:'',
            is_correct:'',//-1 未做 ，1 正确 0  错误
            ct_contractor_info_id:'',
            ct_operator_info_id:'',
            ct_question_test_dtl_copy_id:'',
            ct_question_test_dtl_item_copy_id:'',
            ct_question_test_dtl_item_copy:[],
            id:'',
            types:'',
            max_length:''
        });
        // 点击查看题目
        const ct_question_test_dtl_item_copy_ids = ref([]);
        const checkAnswerKey = (id) =>{
            var answerObj = answerList.value.find(items=>items.id == id);
            Object.keys(answerObj).map(key=>{
                answerDetail[key] = answerObj[key]
            });
            answerList.value.forEach((elem,index)=>{
                if(elem.id == id){
                    checkNum.value = index;
                }
            });
            if(answerDetail.types == 2){
                ct_question_test_dtl_item_copy_ids.value = answerDetail.ct_question_test_dtl_item_copy_id && answerDetail.ct_question_test_dtl_item_copy_id!=''?answerDetail.ct_question_test_dtl_item_copy_id.split(','):[];
            }
        }
        // 获取试卷
        const isloading = ref(false);
        const getAnswerList = () =>{
            http.get({
                method:'query',
                queryId:1065,
                ct_train_id:route.params.id,
                ct_operator_info_id:route.params.userid
            })
            .then(res=>{
              debugger
                var list = res.map;
                if(list.length === 0) return false;
                answercount.value = list && list.length > 0?list[0].count:0;
                var obj = {},answerlist = [];
                list.forEach(element => {
                    if(!obj[element.types]){
                        obj[element.types] = [];
                        obj[element.types].push(element);
                        answerlist.push(element);
                    }else{
                        obj[element.types].push(element);
                        answerlist.push(element);
                    }
                });
                
                Object.keys(obj).map(key=>{
                    answerForm[key] = obj[key]
                });
                answerList.value = answerlist;
                isCheck.value = answerlist[0].id;
                checkNum.value = 0;
                checkAnswerKey(answerlist[0].id);
            })
        }
        // 提交
        const prev = () =>{
            if(checkNum.value > 0){
                checkNum.value = checkNum.value -1;
                isCheck.value = answerList.value[checkNum.value].id;
                checkAnswerKey(answerList.value[checkNum.value].id);
            }
            
        }
        const next = (type) =>{
            if(answerDetail.types == 2){
                answerDetail.ct_question_test_dtl_item_copy_id = ct_question_test_dtl_item_copy_ids.value.join(',');
            }
            if(type == 2){
                if(checkNum.value != answerList.value.length -1){
                    checkNum.value = checkNum.value + 1;
                    isCheck.value = answerList.value[checkNum.value].id;
                    checkAnswerKey(answerList.value[checkNum.value].id);
                }
            }else{
                isloading.value = true;
                if(!checkAnswerKey.id || checkAnswerKey.id ==''){
                  Notify({ type: 'danger', message: '请刷新页面！' });
                  return false;
                }
                http.post(answerDetail)
                .then(()=>{
                    isloading.value = false;
                    answerForm[answerDetail.types].forEach(elem=>{
                        if(elem.id == answerDetail.id){
                            if(elem.types == 1 || elem.types == 3){
                                elem.ct_question_test_dtl_item_copy_id = answerDetail.ct_question_test_dtl_item_copy_id;
                            }else {
                                elem.ct_question_test_dtl_item_copy_id = answerDetail.ct_question_test_dtl_item_copy_id;
                            }
                        }  
                    });
                    if(type == 0){
                        checkNum.value = checkNum.value + 1;
                        isCheck.value = answerList.value[checkNum.value].id;
                        checkAnswerKey(answerList.value[checkNum.value].id);
                    }else{
                        submit();
                    }
                })
                .catch(()=>{
                    isloading.value = false;
                })
            }  
        }
        
        // 计时器
        const setIntervalTime = ref(null);
        const times = ref(0);
        const timeStart = () =>{
            var s = 0; // 秒
            var m = 0; // 分钟
            var h = 0; // 小时
            var sStr = '';
            var mStr = '';
            var hStr = '';
            setIntervalTime.value = setInterval(()=>{
                s++;
                if(s == 60){
                    m++;  // 分钟进位 
                    s = 0;  // s秒 清零
                }
                if(m == 60){
                    h++;  // 小时进位 
                    m = 0;  // m分钟 清零
                }
                // 小时记录可以每24小时清零一次，或者100小时清零一次，都可以
                if(h == 100){
                    h = 0;
                }
                // 如果记录的时间小于0，要做补零操作
                if(s < 10){
                    sStr = '0' + s;
                }else{
                    sStr = s;
                }
                if(m < 10){
                    mStr = '0' + m;
                }else{
                    mStr = m;
                }
                if(h < 10){
                    hStr = '0' + h;
                }else{
                    hStr = h;
                }
                // 每次执行返回一个记录时间的字符串，将这个字符串写入到div中
                times.value = `${hStr}:${mStr}:${sStr}`;
            },1000); 
        }
        const submit = () =>{
            debugger
            Dialog.confirm({
                title: '提示',
                message:'此操作将提交本次答题，请确认？',
            }).then(() => {
              debugger
                isloading.value = true;
                
                http.post({
                    method:'query',
                    queryId:1067,
                    ct_train_id:route.params.id
                })  
                .then(()=>{
                    isloading.value = false;
                    clearInterval(setIntervalTime.value)
                    router.push('/'+route.meta.parent)
                }) 
                .catch(() => {
                    isloading.value = false;
                });  
            })
            .catch(() => {
                isloading.value = false;
            });
        }

        onMounted(()=>{
            getAnswerList();
            timeStart()
        })
        return {
            answerList,
            times,
            answerForm,
            route,
            router,
            checkAnswerKey,
            answerDetail,
            isCheck,
            ct_question_test_dtl_item_copy_ids,
            next,
            prev,
            checkNum,
            answercount,
            submit,
            isloading
            
        }
    },
}
</script>